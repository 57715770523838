<template>
  <div id="haiku">
    <v-card class="" elevation="0">
      <div v-if="haiku">
        <div v-if="haiku.image">
          <div class="example">
            <v-img
              class="haikuimage"
              lazy-src="require('@/assets/ffffff.png')"
              :src="haiku.imageURL"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-container>
              <p>
                {{ haiku_lazy }}
              </p>
            </v-container>
          </div>
        </div>
        <div v-if="!haiku.image">
          <v-container>
            <v-card-title class="headline haikutext">{{
              haiku_noImage
            }}</v-card-title>
          </v-container>
        </div>
        <v-container>
          <v-container>
            <div v-if="haiku.userId !== ''">
              <router-link
                :to="{ name: 'user', params: { userId: haiku.userId } }"
              >
                <div class="title">{{ haiku.haigou }}</div>
              </router-link>
            </div>
            <div v-else>
              <div class="title">{{ haiku.haigou }}</div>
            </div>
            <div class="timestamp">{{ haiku.timestamp }}</div>
            <div class="my-3 subtitle-1 comment">{{ haiku.comment }}</div>
            <v-row align="center" class="mx-0" no-gutters>
              <tags :tags="haiku.tags"></tags>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <heart
                :haikuid="this.$route.params.id"
                :heartCount="haiku.heartCount"
              ></heart>
              <v-spacer></v-spacer>
              <a :href="sns.twitter" target="_blank">
                <v-img
                  :src="require('@/assets/Twitter_Logo_Blue.svg')"
                  alt="logo"
                  class="logo"
                  height="44"
                  max-width="44"
              /></a>
            </v-row>
          </v-container>
        </v-container>
      </div>
    </v-card>
  </div>
</template>
<script>
import { db } from "../plugins/firebase";
import heart from "./Heart";
import tags from "./Tags";

export default {
  name: "Haiku",
  components: {
    heart,
    tags,
  },
  data() {
    return {
      haiku_lazy: "",
      haiku_noImage: "",
      haiku: null,
      sns: {
        twitter: "",
        url: "",
        title: "",
        hashtags: "俳句",
      },
    };
  },
  created: function () {
    let self = this;
    db.ref("haikus/" + this.$route.params.id).once("value", (haikudata) => {
      if (!haikudata.val().publish) {
        return;
      }
      db.ref("users/" + haikudata.val().user).once("value", (snapshot) => {
        let dateTime = new Date(haikudata.val().createdAt);
        var haigou = "名無し";
        var userid = "";
        if (snapshot.val()) {
          haigou = snapshot.val().haigou;
          userid = snapshot.val().userId;
        }
        let data = {
          haikuid: haikudata.key,
          haiku: haikudata.val().haiku,
          image: haikudata.val().image,
          imageURL: haikudata.val().imageURL,
          comment: haikudata.val().comment,
          timestamp:
            dateTime.getFullYear() +
            "年" +
            (dateTime.getMonth() + 1) +
            "月" +
            dateTime.getDate() +
            "日 " +
            dateTime.getHours() +
            ":" +
            ("0" + dateTime.getMinutes()).slice(-2),
          haigou: haigou,
          userId: userid,
          tags: haikudata.val().tags,
          heartCount: haikudata.val().heartCount,
        };
        self.haiku = data;
        self.snsUrl();
        if (data.image) {
          self.setHaiku(self);
        } else {
          self.haiku_noImage = self.haiku.haiku;
        }
      });
    });
  },
  methods: {
    setHaiku(self) {
      //axios で動的にタイトルを変更する場合があるので若干遅らせる
      setTimeout(
        function () {
          self.haiku_lazy = self.haiku.haiku;
        }.bind(self),
        300
      );
    },
    snsUrl() {
      //axios で動的にタイトルを変更する場合があるので若干遅らせる
      setTimeout(
        function () {
          // 現在のurlをエンコード
          this.sns.url = encodeURIComponent(location.href);
          this.sns.title = this.haiku.haiku;
          // this.sns.twitter =
          //   "https://twitter.com/intent/tweet?" +
          //   "&text=" +
          //   this.sns.title +
          //   "%0a" +
          //   "%0a" +
          //   "&url=" +
          //   this.sns.url +
          //   "%0a" +
          //   "%0a" +
          //   "&hashtags=" +
          //   this.sns.hashtags;
           this.sns.twitter =
            "https://twitter.com/intent/tweet?" +
            "&text=" +
            this.sns.title +
            "%0a" +
            "%0a" +
            this.sns.url +
            "%0a" +
            "&hashtags=" +
            this.sns.hashtags;
        }.bind(this),
        1000
      );
    },
  },
};
</script>
<style scoped>
.haikutext {
  padding: 100px 30px 100px 30px;
  font-weight: bold !important;
  /* align-items: center;
  justify-content: center; */
  color: black;
  font-size: 2em;
}
.example {
  position: relative;
  max-width: 1000px;
  /* margin: 0 auto; */
}
p {
  position: absolute;
  display: flex;
  color: white;
  font-weight: bold;
  font-size: 2em;
  top: 0;
  /* width: 100%; */
  height: 100%;
  align-items: center;
  text-shadow: -0px -0px 5px #000000;
  padding: 0 0 0 0;
  margin: 0 auto;

  /* top: 50%;
  left: 50%;
  text-shadow: -1px -2px 3px #808080;
  transform: translate(-80%, -50%);
  padding: 0; */
  max-width: 1000px;
}
.haikuimage {
  max-height: 500px;
}

.comment {
  white-space: pre-wrap;
}
.timestamp {
  display: inline;
  font-size: 12px;
}
</style>
