<template>
  <div class="edit">
    <h3>※画像の編集機能は開発中です</h3>
    <v-container>
      俳句
      <v-form ref="haiku_form">
        <v-row no-gutters>
          <v-textarea
            class="textarea-haiku"
            outlined
            rows="4"
            background-color="white"
            placeholder="俳句"
            :rules="[required]"
            v-model="haiku"
          ></v-textarea>
        </v-row>
        コメント
        <v-row no-gutters>
          <v-textarea
            class="textarea-comment"
            background-color="white"
            rows="4"
            outlined
            placeholder="コメント"
            v-model="comment"
          ></v-textarea>
        </v-row>
        <div>タグを選んで下さい</div>
        <v-card outlined elevation="0">
          <v-container class="py-0">
            <v-row align="center" justify="start">
              <v-col
                v-for="(selection, i) in selections"
                :key="selection.text"
                class="shrink"
              >
                <v-chip
                  :disabled="loading"
                  close
                  @click:close="selectedTag.splice(i, 1)"
                  v-model="tag"
                >
                  {{ selection.text }}
                </v-chip>
              </v-col>
            </v-row>
          </v-container>
          <v-divider v-if="!allSelected"></v-divider>
          <v-list>
            <template v-for="item in categories">
              <v-list-item
                v-if="!selectedTag.includes(item)"
                :key="item.text"
                :disabled="loading"
                @click="selectedTag.push(item)"
              >
                <v-chip
                  :disabled="loading"
                  close
                  outlined
                  @click:close="selectedTag.splice(i, 1)"
                  v-model="tag"
                  v-text="item.text"
                >
                </v-chip>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
        <v-row align="center">
          <v-container>
            <v-btn
              class="ma-3"
              depressed
              outlined
              color="primary"
              large
              @click="cancel"
              >キャンセル</v-btn
            >
            <v-btn
              class="ma-3"
              depressed
              color="primary"
              large
              @click="createPost"
              >保存</v-btn
            >
            <v-btn
              class="ma-3"
              depressed
              outlined
              color="error"
              large
              @click="deleteHaiku_kakunin"
              >この俳句を削除</v-btn
            >
          </v-container>
        </v-row>
      </v-form>
    </v-container>

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline"> 保存しました。 </v-card-title>
          <v-card-text>公開する場合、公開設定を変更してください。</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="closeDialg">
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog_error" max-width="290">
        <v-card>
          <v-card-title class="headline"> 俳句は必須入力です。 </v-card-title>
          <v-card-text></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="closeDialg_error">
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog_kakunin" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">
            削除します。よろしいですか？
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="closeDialg_deletekakunin"
            >
              キャンセル
            </v-btn>
            <v-btn color="green darken-1" text @click="deleteHaiku">
              削除
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_deletekanryou" persistent max-width="290">
        <v-card>
          <v-card-title class="headline"> 削除しました。 </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="closeDialg_deletekanryou"
            >
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { db } from "../plugins/firebase";
import mixin from "../plugins/mixin.js";
import firebase from "firebase/app";

export default {
  name: "edit",
  mixins: [mixin],
  data() {
    return {
      haiku: "",
      comment: "",
      required: (value) => !!value || "",
      tag: "",
      dialog: false,
      tags: [],
      search: "",
      selectedTag: [],
      loading: false,
      dialog_error: false,
      createdAt: "",
      haikudata: "",
      dialog_kakunin: false,
      dialog_deletekanryou: false,
    };
  },
  created: function () {
    db.ref("haikus/" + this.$route.params.haikuId).once(
      "value",
      (haikudata) => {
        this.haikudata = haikudata.val();
        this.haiku = haikudata.val().haiku;
        this.comment = haikudata.val().comment;
      }
    );

    db.ref("tags").once("value", (snapShot) => {
      let data = [];
      snapShot.forEach(function (tag) {
        data.push({
          text: tag.val().text,
          tagId: tag.key,
        });
      });
      this.tags = data;
    });
  },
  methods: {
    next() {
      this.loading = true;

      setTimeout(() => {
        this.selectedTag = [];
        this.loading = false;
      }, 2000);
    },
    createPost: function () {
      if (this.$refs.haiku_form.validate()) {
        var updates = {};
        // updates["/haikus/" + this.$route.params.haikuId] = {
        //   haiku: this.haiku,
        //   createdAt: this.haikudata.createdAt,
        //   comment: this.comment,
        //   user: this.user.uid,
        //   updatedAt: firebase.database.ServerValue.TIMESTAMP,
        //   heartCount: this.haikudata.heartCount,
        //   publish: false,
        //   image: this.haikudata.image,
        //   imageURL: this.haikudata.imageURL,
        //   tags: this.selectedTag,
        // };

        updates[
          "/haikus/" + this.$route.params.haikuId + "/haiku"
        ] = this.haiku;
        // {
        //   haiku: this.haiku,
        // };
        updates[
          "/haikus/" + this.$route.params.haikuId + "/comment"
        ] = this.comment;
        updates["/haikus/" + this.$route.params.haikuId + "/updatedAt"] =
          firebase.database.ServerValue.TIMESTAMP;
        updates["/haikus/" + this.$route.params.haikuId + "/publish"] = false;
        updates[
          "/haikus/" + this.$route.params.haikuId + "/tags"
        ] = this.selectedTag;
        db.ref().update(updates);
        if (status) {
          this.dialog_publish = true;
        } else {
          this.dialog_unpublish = true;
        }

        this.dialog = true;
      } else {
        this.dialog_error = true;
      }
    },
    cancel: function () {
      this.$router.push("/");
    },
    closeDialg: function () {
      this.dialog = false;
      this.$router.push("/mypage");
    },
    closeDialg_error: function () {
      this.dialog_error = false;
    },
    deleteHaiku_kakunin: function () {
      this.dialog_kakunin = true;
    },
    closeDialg_deletekakunin: function () {
      this.dialog_kakunin = false;
    },
    deleteHaiku: function () {
      this.dialog_kakunin = false;
      db.ref("haikus_archive").push(this.haikudata);
      db.ref("haikus/" + this.$route.params.haikuId).remove();
      this.dialog_deletekanryou = true;
    },
    closeDialg_deletekanryou: function () {
      this.dialog_deletekanryou = false;
      this.$router.push("/mypage");
    },
  },

  computed: {
    allSelected() {
      return this.selectedTag.length === this.tags.length;
    },
    categories() {
      return this.tags;
    },
    selections() {
      const selections = [];
      for (const selection of this.selectedTag) {
        selections.push(selection);
      }
      return selections;
    },
  },
};
</script>
<style scoped>
h1,
h2 {
  font-weight: normal;
}
a {
  color: #42b983;
}
.new {
  /* margin-top: 20px; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.v-text-field--outlined >>> fieldset {
  border-color: #dcdcdc;
}
</style>

