var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{style:({ background: _vm.$vuetify.theme.themes.light.background }),attrs:{"id":"app"}},[_c('div',{staticClass:"overflow-hidden"},[_c('v-app-bar',{attrs:{"elevation":"0","color":"white"}},[_c('v-img',{staticClass:"logo",attrs:{"src":require('@/assets/logo.svg'),"alt":"logo","height":"22","max-width":"120"},on:{"click":_vm.clicklogo}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),(!this.user.uid)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"padding-right":"10px"}},'v-icon',attrs,false),on),[_vm._v("mdi-account-circle-outline")])]}}],null,false,1906944125)},[_c('v-list',[_c('v-list-item',[_c('router-link',{attrs:{"to":"/signin"}},[_c('v-list-item-title',[_vm._v("ログイン")])],1)],1),_c('v-list-item',[_c('router-link',{attrs:{"to":"/signup"}},[_c('v-list-item-title',[_vm._v("会員登録")])],1)],1),_c('v-list-item',[_c('router-link',{attrs:{"to":"/about"}},[_c('v-list-item-title',[_vm._v("俳句ノートとは？")])],1)],1)],1)],1):_vm._e(),(this.user.uid)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"padding-right":"10px"}},'v-icon',attrs,false),on),[_vm._v("mdi-account-circle")])]}}],null,false,2539228112)},[_c('v-list',[_c('v-list-item',[_c('router-link',{attrs:{"to":{
                name: 'user',
                params: { userId: this.userdetail.userId },
              }}},[_c('v-list-item-title',[_vm._v(_vm._s(this.userdetail.haigou))])],1)],1),_c('v-list-item',[_c('router-link',{attrs:{"to":"/mypage"}},[_c('v-list-item-title',[_vm._v("俳句集")])],1)],1),_c('v-list-item',[_c('router-link',{attrs:{"to":"/settings"}},[_c('v-list-item-title',[_vm._v("設定")])],1)],1),_c('v-list-item',{on:{"click":_vm.signOut}},[_c('v-list-item-title',[_vm._v(" ログアウト")])],1)],1)],1):_vm._e(),_c('v-btn',{attrs:{"depressed":"","outlined":"","to":{ name: 'new' }}},[_vm._v(" 俳句を詠む ")])],1)],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }