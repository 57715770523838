<template>
  <div>
    <v-container>
      <banner></banner>
      <banner-osirase></banner-osirase>
      <v-row dense>
        <v-col cols="12">
          <div v-if="tags">
            <v-sheet elevation="0" color="#f7f9f9">
              <v-chip-group mandatory active-class="primary--text">
                <v-chip class="tagChips" outlined> ホーム </v-chip>
                <v-chip
                  class="tagChips"
                  v-for="tag in tags"
                  :key="tag.index"
                  outlined
                  v-bind:class="{ active: tag.selected }"
                  @click="selectTag(tag.key)"
                >
                  {{ tag.text }}
                </v-chip>
              </v-chip-group>
            </v-sheet>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <h3>新着順</h3>
        </v-col>
        <!-- <v-col cols="6"  align="end" >
          <router-link :to="{ name: 'about' }"> 俳句ノートってなに？ </router-link>
        </v-col> -->
      </v-row>
      <div v-for="item in sortedItem" v-bind:key="item.haikuid">
        <haikucard :haiku="item"></haikucard>
        <!-- <haikucardPicture></haikucardPicture> -->
      </div>
    </v-container>
    <!-- <footerHaikulife></footerHaikulife> -->
  </div>
</template>
<script>
import { db } from "../plugins/firebase";
import haikucard from "./Haikucard";
import banner from "./Banner";
import bannerOsirase from "./BannerOsirase";
// import footerHaikulife from "./Footer";
// import haikucardPicture from "./HaikucardPicture";

export default {
  name: "Home",
  components: {
    haikucard,
    banner,
    bannerOsirase,
    // footerHaikulife,
    // haikucardPicture,
  },
  data() {
    return {
      items: [],
      tags: [],
    };
  },
  created: function () {
    let self = this;
    db.ref("haikus")
      .orderByChild("publish")
      .equalTo(true)
      .once("value", (haikus) => {
        //俳句に紐づくユーザー情報を取得
        let data = [];
        haikus.forEach(function (haiku) {
          db.ref("users/" + haiku.val().user).once("value", (snapshot) => {
            var haigou = "名無し";
            var userid = "";
            if (snapshot.val()) {
              haigou = snapshot.val().haigou;
              userid = snapshot.val().userId;
            }
            // let dateTime = new Date(haiku.val().createdAt);
            data.push({
              haikuid: haiku.key,
              haiku: haiku.val().haiku,
              comment: haiku.val().comment,
              timestamp: haiku.val().createdAt,
              haigou: haigou,
              userId: userid,
              tags: haiku.val().tags,
              heartCount: haiku.val().heartCount,
            });
          });
        });
        self.items = data;
      });
    db.ref("tags/")
      .orderByChild("order")
      .once("value", (snapshot) => {
        self.tags = [];
        snapshot.forEach(function (tag) {
          if (tag.val().publish) {
            if (tag.key === self.$route.params.tagId) {
              self.tags.push({
                text: tag.val().text,
                key: tag.key,
                selected: true,
              });
              self.selectionTagText = tag.val().text;
            } else {
              self.tags.push({
                text: tag.val().text,
                key: tag.key,
                selected: false,
              });
            }
          }
        });
      });
  },
  computed: {
    sortedItem() {
      return this.items.slice().sort((a, b) => {
        if (a.timestamp < b.timestamp) return 1;
        if (a.timestamp > b.timestamp) return -1;
        return 0;
      });
    },
  },
  methods: {
    selectTag: function (selectTagIndex) {
      this.$router.push({
        name: "category",
        params: { tagId: selectTagIndex },
      });
    },
  },
};
</script>
<style>
.v-application a {
  color: black !important;
}
</style>

