<template>
  <v-app
    id="app"
    :style="{ background: $vuetify.theme.themes.light.background }"
  >
    <div class="overflow-hidden">
      <v-app-bar elevation="0" color="white">
        <v-img
          :src="require('@/assets/logo.svg')"
          alt="logo"
          class="logo"
          height="22"
          max-width="120"
          @click="clicklogo"
        />
        <!-- <img class="img" src="./assets/logo.svg" height="30" /> -->
        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-menu v-if="!this.user.uid">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" style="padding-right: 10px"
              >mdi-account-circle-outline</v-icon
            >
          </template>
          <v-list>
            <v-list-item>
              <router-link to="/signin"
                ><v-list-item-title>ログイン</v-list-item-title>
              </router-link>
            </v-list-item>
            <v-list-item>
              <router-link to="/signup">
                <v-list-item-title>会員登録</v-list-item-title>
              </router-link>
            </v-list-item>
            <v-list-item>
              <router-link to="/about">
                <v-list-item-title>俳句ノートとは？</v-list-item-title>
              </router-link>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu v-if="this.user.uid">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" style="padding-right: 10px"
              >mdi-account-circle</v-icon
            >
            <!-- <div v-bind="attrs" v-on="on">{{this.userdetail.userId}}</div> -->
          </template>
          <v-list>
            <v-list-item>
              <router-link
                :to="{
                  name: 'user',
                  params: { userId: this.userdetail.userId },
                }"
              >
                <v-list-item-title>{{
                  this.userdetail.haigou
                }}</v-list-item-title>
              </router-link>
            </v-list-item>
            <v-list-item>
              <router-link to="/mypage">
                <v-list-item-title>俳句集</v-list-item-title>
              </router-link>
            </v-list-item>
            <v-list-item>
              <router-link to="/settings">
                <v-list-item-title>設定</v-list-item-title>
              </router-link>
            </v-list-item>
            <v-list-item @click="signOut">
              <v-list-item-title> ログアウト</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn depressed outlined :to="{ name: 'new' }"> 俳句を詠む </v-btn>
      </v-app-bar>
    </div>
    <router-view />
  </v-app>
</template>

<script>
import mixin from "./plugins/mixin.js";
import firebase from "firebase/app";

export default {
  mixins: [mixin],
  methods: {
    signOut: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          alert("ログアウトしました。");
        });
      this.$router.push("/").catch(() => {});
    },
    clicklogo: function () {
      this.$router.push("/").catch((e) => {
        console.log(e);
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 850px;
  margin: 0 auto;
}
a {
  text-decoration: none;
}
.logo {
  height: 30px;
}
.v-btn--outlined >>> fieldset {
  border-color: #dcdcdc;
}
.v-app-bar {
  border-color: #000000;
}
</style>
